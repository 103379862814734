<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.name"
      :rules="MIXINS_REQUIRED_RULES"
      label="Name"
      dense
      filled
      class="rounded-lg"
    />
    <v-text-field
      v-model="form.location"
      :rules="MIXINS_REQUIRED_RULES"
      label="Location"
      dense
      filled
      class="rounded-lg"
    />
    <div v-if="allowMultipleLocations">
      <div>
        <small>Please check timezones if selecting multiple locations.</small>
      </div>
      <v-checkbox
        v-for="(location, key) in locations"
        :key="key"
        v-model="form.location_ids"
        :value="location.id"
        :label="location.name"
      ></v-checkbox>
    </div>
    <location-select-box
      v-else
      v-model="form.location_id"
      :rules="MIXINS_REQUIRED_RULES"
      item-text="name"
      item-value="id"
      label="Tag Location"
      dense
      filled
    ></location-select-box>
    <v-textarea
      v-model="form.notes"
      :rules="MIXINS_REQUIRED_RULES"
      label="Notes"
      dense
      filled
      rows="3"
      class="rounded-lg"
    />
    <public-private-select-box
      v-model="form.type"
      :rules="MIXINS_REQUIRED_RULES"
      label="Type"
      dense
      filled
    />
    <div class="mt-4 d-flex justify-space-between">
      <div>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.startDate"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg mr-2"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-date-picker v-model="form.startDate"></v-date-picker>
        </v-menu>
      </div>
      <div>
        <v-menu
          ref="startTimePickerMenu"
          v-model="startTimePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.startTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.startTime"
              label="Start Time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="startTimePicker"
            v-model="form.startTime"
            full-width
            @click:minute="$refs.startTimePickerMenu.save(form.startTime)"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
    <div class="mt-4 d-flex justify-space-between">
      <div>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.endDate"
              label="End Date"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg mr-2"
            ></v-text-field>
            <!-- :rules="MIXINS_REQUIRED_RULES" -->
          </template>
          <v-date-picker v-model="form.endDate"></v-date-picker>
        </v-menu>
      </div>
      <div>
        <v-menu
          ref="endTimePickerMenu"
          v-model="endTimePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.endTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.endTime"
              label="End Time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
            ></v-text-field>
            <!-- :rules="MIXINS_REQUIRED_RULES" -->
          </template>
          <v-time-picker
            v-if="endTimePicker"
            v-model="form.endTime"
            full-width
            @click:minute="$refs.endTimePickerMenu.save(form.endTime)"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
    <v-file-input
      accept="image/*"
      v-model="form.image"
      label="Thumbnail"
      dense
      filled
      class="rounded-lg"
      prepend-icon="mdi-file-image-outline"
      v-if="!isUpdateOperation"
    />
  </v-form>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const locationRepository = RepositoryFactory.get("location");

const PublicPrivateSelectBox = () =>
  import("@/components/miscellaneous/selects/PublicPrivateSelectBox.vue");

export default {
  components: {
    PublicPrivateSelectBox
  },
  data: () => ({
    valid: false,
    startDateMenu: false,
    startTimePicker: false,
    endDateMenu: false,
    endTimePicker: false,
    locations: []
  }),

  props: {
    /**
     * Collection of data that will be supplied to this component
     */
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    },
    allowMultipleLocations: {
      type: Boolean,
      default: false
    },
    eventID: {
      type: Number,
      default: null
    }
  },

  computed: {
    isUpdateOperation() {
      return !!this.eventID;
    }
  },

  methods: {
    validate() {
      console.log(this.valid);
      return this.$refs.form.validate();
    },
    getLocations() {
      let params = {
        full_data: true
      };

      locationRepository
        .get({ params })
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          if (this.allOption) {
            this.locations = [
              // {
              //   id: null,
              //   name: "All",
              // },
              ...items
            ];
          } else {
            this.locations = items;
          }

          this.setDefaultLocationValue();
        })
        .catch(error => {
          console.log({ error }, "LocationSelectBox");
        });
    }
  },
  mounted() {
    if (this.allowMultipleLocations) {
      this.getLocations();
    }
  }
};
</script>
